import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircleIcon from '@mui/icons-material/Circle';

export const Job = (key) => {
  const job = key.job
  return (
    <Accordion>
        <AccordionSummary
                  expandIcon={<ExpandMoreIcon color='black'/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
            <Box width='100%'>
    <Grid xs={12} container alignItems="center">
    <Grid item md={6} xs={12}>
        <Typography  variant="h3" component="div">
        {job.company}
        </Typography>
        <Typography color="text.secondary" variant="h4">
    {job.position}
    </Typography>
    </Grid>
    <Grid item md={6} xs={12}>
    <Typography textAlign="right"  variant="h3" component="div">
        {job.dateRange}
        </Typography>
    </Grid>
    </Grid>
    </Box>
    </AccordionSummary>
    <AccordionDetails>
        <Box marginLeft="1em">
        <Typography  variant="h5" component="div">
        {job.description}
        </Typography> 
        <List dense>

            {job.bullets.map(bullet => {
                return(<ListItem alignItems="flex-start">
                    <CircleIcon fontSize='inherit' style={{padding: '5px'}}></CircleIcon>
                  <Typography variant="h5">{bullet}</Typography>
                </ListItem> )
            })}
               

            </List>
        </Box></AccordionDetails>
    </Accordion>
  )
}

export default Job
