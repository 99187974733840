import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Job from '../Job';
import eol25 from '../../elr25.png'
import ej25 from '../../ej25.png'
import resume from '../../resume.json'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircleIcon from '@mui/icons-material/Circle';

export const Resume = () => {
    return (
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Box sx={{ my: 3, mx: 2 }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography color="hotpink" gutterBottom variant="h1" component="h1">
                Emily Laughren
              </Typography>
              <Typography color="text.secondary" variant="h4">
            Full-stack developer with experience in cloud computing, microservice architectures, DevOps, and data standards.
          </Typography>
            </Grid>
            <Grid item>
              <Box>
                <img src={eol25}></img>
              </Box>
            </Grid>
          </Grid>
          <Divider gutterBottom variant="middle"><Typography color="hotpink" variant="h3">SKILLS</Typography></Divider>
          <Box marginLeft="1em">
        <List dense>
                <ListItem alignItems="flex-start">
                  <Typography variant="h4">Languages: Javascript (ReactJS, NodeJS, D3), HTML5/CSS, Python (Django), C#/.Net, Java (SpringMVC, Ant, Maven),
Powershell, PHP, XML, SQL, Go</Typography>
                </ListItem> 
                <ListItem alignItems="flex-start">
                  <Typography variant="h4">Tools: Git, Eclipse, Visual Studio/Visual Studio Code, VirtualBox, XMLSpy</Typography>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <Typography variant="h4">Other Experience: Amazon Web Services, NIEM, WordPress, Drupal, SoapUI, Apache Kafka</Typography>
                </ListItem> 

            </List>
        </Box>
        <Divider gutterBottom variant="middle"><Typography color="hotpink" variant="h3">EXPERIENCE</Typography></Divider>
        <Box>

            {resume.map(job => {
                return(<Job key={job.company} job={job}/>)
            })}

        </Box>
        <Divider gutterBottom variant="middle"><Typography color="hotpink" variant="h3">EDUCATION</Typography></Divider>
        <Box>
        <Grid xs={12} container alignItems="center">
            <Grid item xs={6}>
                <Typography variant="h3">Boston University</Typography>
                <Typography variant="h4">College of Arts and Sciences</Typography>
                <Typography variant="h4">B.A. in Computer Science</Typography>
            </Grid>
            <Grid item xs={6}>
            <Typography variant="h3" textAlign="right">June 2013</Typography>
            </Grid>
        </Grid>
        </Box>
        <Divider gutterBottom variant="middle"><Typography color="hotpink" variant="h3">CONTACT</Typography></Divider>
        <Box>
        <Grid xs={12} container alignItems="center">
            <Grid item md={6} xs={12}>
                <Typography variant="h3">Email: emily@emilylaughs.com</Typography>
                <Typography variant="h3">Github: emilylaughs</Typography>
            </Grid>
            <Grid item  md={6} xs={12}>
            <Typography variant="h3" textAlign="right">Phone: 301-452-3355</Typography>
            </Grid>
        </Grid>
        </Box>
        <Divider gutterBottom variant="middle"><Typography color="hotpink" variant="h5">STAY ASSIDUOUS</Typography></Divider>
              <Box style={{display: "flex"}} justifyContent="center">
                <img src={ej25}></img>
              </Box>
        </Box>
      </Box>
    );
  }

  export default Resume